//
// Quick Search
//

.ced_wrap_search {
  background-color: #cbe9ff;
  padding: 20px 0px;
  border-radius: 10px;
}

.sort-filter-text {
  color: #979797;
  font-weight: 500;
}

.sort-filter-menu {
  border-radius: 1rem;
  margin-top: 1.3rem;
  padding: 0;
  min-width: 14rem;
}

.sort-filter-menu-header {
  border-radius: 1rem 1rem 0 0;
  padding: 0.2rem;
  height: 2.4rem;
}

.sort-filter-menu-item {
  padding: 0.45rem 0.7rem;
}

.divider-menu {
  width: 10.5rem;
  margin: 0.5rem auto !important;
}

.document-card {
  width: 100%;
  border-radius: 1.3rem;
  background: white;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  color: #434343;
  margin: 0;
  align-items: center;
}

.moreSections {
  justify-content: flex-start;
  gap: 0px;
  padding: 0;
}

.document-type-dropdown {
  display: inline;
}

.expandAllSection {
  padding: 0;
  border-radius: 1.3rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown-item:active .favorite-text, .dropdown-item:active .sort-filter-text {
  color: #fff;
}

.favorite-text {
  margin: 0.3rem 1rem;
  color: #979797;
  font-weight: 500;
  font-family: "Poppins";
  font-style: normal;
}

.expandMoreSection {
  padding: 0;
}

.more-options-icon {
  margin-top: 0.1rem;
}

.ribbon-icon {
  width: 4.5rem;
  height: 2.5rem;
  left: -0.55rem;
  top: 2rem;
  z-index: 2;
  position: absolute;
}

.expand-more {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.score-slider {
  padding: 1.13rem 1rem 0.7rem 2.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.expand-more-btn {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem 0.5rem 1.3rem;
  border-radius: 1.3rem 0 1.3rem 0;
  font-size: 11px;
  line-height: 1;
}

.document-card .document-card-container {
  padding: 1.7rem;
  width: 100%;
  height: 100%;
  min-height: 158px;
  background: white;
  border-radius: 1.3rem;
  position: relative;
}

.quick-search {
  .ced_search_title {
    margin-bottom: 20px;
  }

  // Form
  .quick-search-form {
    padding: 0;

    .input-group {
      background-color: transparent;
    }

    .input-group-prepend,
    .input-group-append {
      background-color: transparent;

      .input-group-text {
        background-color: transparent;
        border: 0;
      }
    }

    .input-group-append {
      padding: 0;
      justify-content: center;
      align-items: center;
    }

    .form-control {
      @include input-reset();
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      padding: 0;
      border-radius: 0;
    }

    .quick-search-close {
      cursor: pointer;
      display: none;
    }
  }

  // Wrapper
  .quick-search-wrapper {
    display: none;

    // Scroll
    &.ps {
      padding-right: 10px !important;
      margin-right: -10px !important;
    }
  }

  // Result
  .quick-search-result {
    padding: 0;
    margin: 0;
  }

  // Show results
  &.quick-search-has-result {
    .quick-search-wrapper {
      display: block;
    }
  }

  // Dropdown mode
  &.quick-search-dropdown {
    padding: 0.5rem 1.5rem;

    // Form
    .quick-search-form {
      background-color: #fff;
      border-radius: 5px;
      padding: 0.5rem 1rem 0.5rem 1rem;

      .input-group {
        &:before {
          right: 0;
        }

        .input-group-prepend {
          .input-group-text {
            padding-left: 0;
          }
        }

        .input-group-append {
          .input-group-text {
            padding-right: 0;
          }
        }
      }
    }

    // Wrapper
    .quick-search-wrapper {
      margin: 1.5rem 0 0.5rem 0;
    }

    // Show results
    &.quick-search-has-result {
      .quick-search-form {
        border-bottom: 1px solid $gray-200;
        padding-bottom: 0.75rem;
      }
    }
  }

  // Inline mode
  &.quick-search-inline {
    // Dropdown menu
    .dropdown-menu {
      padding: 1.5rem;
    }
  }

  // Offcanvas mode
  &.quick-search-offcanvas {
    // Form
    .quick-search-form {
      margin-bottom: 1.5rem;

      .form-control {
        font-size: 1rem;
        font-weight: 500;
      }

      .input-group {
        &:before {
          right: 0;
        }

        .input-group-prepend {
          .input-group-text {
            padding-left: 0;
          }
        }

        .input-group-append {
          .input-group-text {
            padding-right: 0;
          }
        }
      }
    }

    // Wrapper
    .quick-search-wrapper {
      display: block;
    }
  }
}
