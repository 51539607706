@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import "./general/template/assets/sass/style.react.scss";

@import "./general/template/assets/sass/themes/layout/header/base/light.scss";
@import "./general/template/assets/sass/themes/layout/header/menu/light.scss";
@import "./general/template/assets/sass/themes/layout/brand/dark.scss";
@import "./general/template/assets/sass/themes/layout/aside/dark.scss";

.brand-dark {
  @import "./general/template/assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./general/template/assets/sass/themes/layout/brand/light.scss";
}

@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Black.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-900.woff2")
      format("woff2");
  font-weight: 900;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Bold.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-700.woff2")
      format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-ExtraBold.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-800.woff2")
      format("woff2");
  font-weight: 800;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-ExtraLight.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-200.woff2")
      format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Light.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-300.woff2")
      format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Medium.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-500.woff2")
      format("woff2");
    font-weight: 500;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Regular.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-regular.woff2")
      format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-SemiBold.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-600.woff2")
      format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "Poppins";
  font-display: swap;
  src: local("Poppins"),
    url("../public/media/general/fonts/Poppins/Poppins-Thin.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Poppins/poppins-v20-latin-100.woff2")
      format("woff2");
  font-weight: 100;
}

// Roboto fonts

@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Thin.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-100.woff2")
      format("woff2");
  font-weight: 100;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Light.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-300.woff2")
      format("woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Regular.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-regular.woff2")
      format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Medium.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-500.woff2")
      format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Bold.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-700.woff2")
      format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "Roboto";
  font-display: swap;
  src: local("Roboto"),
    url("../public/media/general/fonts/Roboto/Roboto-Black.ttf")
      format("truetype"),
    url("../public/media/general/fonts/Roboto/roboto-v30-latin-900.woff2")
      format("woff2");
  font-weight: 900;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}
#language-panel-tooltip {
  z-index: 999999 !important;
  transition-delay: 500ms;
}
.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

em {
  color: #3699ff;
}

.display-block {
  display: block;
}

mark {
  padding: 0;
  background-color: unset;
  color: #3699ff;
}

.labelColor {
  color: #626262;
}

.btnLoader {
  min-width: 110px;
}

.listcol {
  padding-left: 0 !important;
}

.alignTop {
  vertical-align: top !important;
}

.filterTextColor {
  color: #0d76a6;
}

.showActive {
  display: block;
}

.hideActive {
  display: none;
}

.updateCursor {
  cursor: pointer;
}

.filter_layout .col-md-1 {
  flex: 0 0 11.666667%;
  max-width: 11.666667%;
}

.filter_layout .col-md-8 {
  flex: 0 0 72.666667%;
  max-width: 72.666667%;
}

.filter_layout .col-md-2 {
  flex: 0 0 14.666667%;
  max-width: 14.666667%;
}

.report-style-class {
  height: 100%;

  iframe {
    border: 0px solid #000 !important;
  }
}

.modal-body-list {
  border: 1px solid #e5eaee !important;
  border-radius: 12px;
}

.powerBiMode .container {
  padding-left: 0;
  padding-right: 0;
}

.searchLayout {
  table-layout: auto !important;
  border-collapse: separate;
}

.searchLayout thead {
  display: none;
}

.publicationLayout {
  table-layout: auto !important;
}

#kt_wrapper {
  background-color: #f5f6f8;
}

.searchLayout td {
  border-top: 0;
  padding: 0 !important;
}

.searchLayout .listcol {
  border-radius: 1rem;
}

.searchLayout .actionCol {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.w-10 {
  width: 10%;
}

.publicationLayout .w-10 {
  width: 10%;
}

.rest-sorting:hover {
  text-decoration: underline;
}

.searchPaginate {
  background: white;
  padding: 1rem;
  border-radius: 1rem;
}

.secondaryText {
  padding: 1rem 2.25rem !important;
}

.fbb-header-popoup-custom .card-title {
  width: calc(100% - 70px) !important;
}

.adminComp {
  position: absolute;
  top: 8px;
  left: 20px;
}

.adminClear {
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}

.btn-custom,
.btn-custom:hover {
  background-color: #979797 !important;
  color: #ffffff;
  border-color: #979797 !important;
}

.filterInactive {
  pointer-events: none;
  opacity: 0.4;
}

.pub_hide_border {
  border-bottom: unset;
}

.symbol-group {
  display: flex;
  align-items: center;

  .symbol {
    position: relative;
    z-index: 0;
    margin-left: -3px;
    transition: all 0.3s ease;
    border: none;
    box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: unset;
      z-index: 0;
    }
  }
}

.fav_items > svg rect {
  fill: #f3f6f9 !important;
}

.fav_items {
  background-color: #f3f6f9 !important;
}

.fav_items_po path:nth-child(1) {
  fill: #f3f6f9 !important;
}

.fav_status path {
  fill: #ffa800 !important;
}

.parliament .filterTextColor {
  min-width: 50px;
}
.checkbox > input:checked ~ span {
  background-color: #ebedf3;
}
.checkbox > input:checked ~ span:after {
  border-color: #3699ff;
}
.table-responsive {
  overflow-x: unset;
}
.searchLayout .document-card {
  margin-bottom: 1rem;
}
.code_help_icon {
  display: none;
}
.aside-minimize .code_help_text {
  display: none;
}
.aside-minimize-hover .code_help_text {
  display: block;
}
.aside-minimize .code_help_icon {
  display: block;
}
.aside-minimize-hover .code_help_icon {
  display: none;
}
