//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary: #3699ff; // Bootstrap variable
$primary-hover: #187de4; // Custom variable
$primary-light: #e1f0ff; // Custom variable
$primary-inverse: #ffffff; // Custom variable
$gray-800: #3f4254;
$fav-search: #dadada;
$po-text: #4A4B68;
$po-search: #80808F;

//custom variables

$title-color: #212121; // Custom variable
$text-label: #000000; // Custom variable
$text-label-light: #464e5f; // Custom variable
$fav-items-color: #13a8c6;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-rem: 1.275rem;
$btn-color-comp: #979797;
$news-feed-title: #A1A5B7;
$help-text: #002333;

$theme-text-colors: (
  "title": $title-color,
  "label": $text-label,
  "label-light": $text-label-light,
  "modal-title": $gray-800,
  "fav-items-color": $fav-items-color,
  "fav-search": $fav-search,
  "po-text": $po-text,
  "po-search": $po-search,
  "news-title": $news-feed-title,
  "help-text": $help-text
);

$font-sizes: (
  "font-size-11": $font-size-11,
  "font-size-12": $font-size-12,
  "font-size-13": $font-size-13,
  "font-size-14": $font-size-14,
  "font-size-16": $font-size-16,
  "font-size-18": $font-size-18,
  "font-size-rem": $font-size-rem
);
